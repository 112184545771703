.layout-header {
    position: fixed;
    top: 20px;
    left: 0;
    width: 100vw;
    height: 55px;
    z-index: 1000;
    padding: 0 40px;

    .header-center {
        position: absolute;
        top: 36%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 30px;
        // gap: 5px;
        .welcome-date {
            color: var(--gray-900);
            font-size: 14px;
            font-weight: 300;
            margin: 0;
            text-align: center;
        }
        .welcome-message {
            color: var(--gray-800);
            font-size: 28px;
            font-weight: 500;
            text-align: center;
            margin-top: 5px;
            line-height: 1;
        }
    }
}
