html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
}

.canvas-container {
    height: 100vh;
    width: 100vw;
}
