.logo-profile {
    // height: 55px;
    .logo-avatar {
        height: 30px;
        width: 30px;
        border-radius: 4px;
    }

    .profile-image {
        border: 2px solid transparent;
        outline: 2px solid var(--gray-500);
        height: 39px;
        width: 39px;
        border-radius: 50%;
    }

    .profile-dropdown {
        position: relative;
        display: flex;
        gap: 10px;
        align-items: center;

        .name-with-arrow {
            position: relative;
            display: flex;
            align-items: center;
            gap: 9px;
            cursor: pointer;

            .name-text {
                font-size: 18px;
                color: var(--gray-900);
                font-weight: 300;
            }

            .dropdown-icon {
                display: flex;
                align-items: center;
            }
        }
    }

    .dropdown {
        position: absolute;
        top: 50px;
        left: -10px;
        width: 280px;
        text-align: left;
        min-height: 400px;
        background-color: #ffffff;
        border: 1px solid var(--gray-100);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        z-index: 2;
        padding: 16px 20px 16px;
        border-radius: 2%;
        /* Animation setup */
        transform: translateY(-100%);
        opacity: 0;
        overflow: hidden;
        transition:
            max-height 1s ease-in-out,
            transform 1s ease-out,
            opacity 1s ease-out;
    }

    .show-dropdown {
        // max-height: 300px;
        transform: translateY(0);
        opacity: 1;
    }
}

.dropdown-tile {
    cursor: pointer;
    .text {
        font-weight: 300;
        font-size: 16px;
        color: var(--gray-900);
    }
}

.user-data {
    margin-top: 10px;
    padding-top: 20px;
    padding-left: 0px;
    border-top: 1px solid Var(--gray-100);
}

.dropdown-item {
    display: block;
    margin-bottom: 20px;
    text-align: left;
    cursor: pointer;

    .dropdown-title {
        display: block;
        font-size: 16px;
        font-weight: 300;
        color: var(--gray-900);

        &.disabled {
            color: var(--gray-500);
        }
    }

    .dropdown-subtitle {
        display: block;
        color: var(--gray-400);
    }
}

.dropdown-footer {
    margin-top: 50px;
    color: var(--gray-500);
    font-size: 13px;
    padding-bottom: 16px;
    font-weight: 400;

    .footer-subtext {
        font-size: 12px;
    }
}

.user-data-white-form {
    position: relative;

    &:hover {
        .actions {
            display: block;
        }
    }

    .actions {
        display: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        .icon {
            font-size: 20px;
        }
    }
}

.accordion-edit-form {
    display: grid;
    align-items: center;
    grid-template-columns: auto 58px;
    margin-bottom: 20px;
    input {
        height: 25px;
        width: 100%;
        border-radius: 4px;
        outline: 2px solid var(--blue-500);
        padding: 0 5px;
    }
    button {
        height: 30px;
        width: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        font-size: 16px;
        &.save {
            color: var(--green-500);
        }
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}
