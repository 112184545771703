@import "./mixins/grid";

.waiting-room {
    height: 100vh;
    width: 100vw;
    background-color: white;
    padding: 10px 16px 0;

    @include media-gt-phone {
        display: grid;
        grid-template-columns: 250px auto 330px;
        grid-template-rows: 100%;
        grid-column-gap: 15px;
        padding: 20px 20px 0 20px;

        @include media-custom-min(1430px) {
            grid-template-columns: 270px auto 380px;
        }
    }

    .grid-column {
        display: none;

        @include media-gt-phone {
            display: block;
        }

        &.activity-grid {
            overflow: hidden;
        }
    }

    $main-grid-offset: 30px;

    .main-grid {
        position: relative;
        display: block;
        padding-top: $main-grid-offset;
        .main-grid-wrapper {
            padding: 20px 16px 0;
            border-radius: 8px;
            background-color: #fafafa;
            height: 100%;
            border-top: 1px solid var(--gray-100);
            border-left: 1px solid var(--gray-100);
            border-right: 1px solid var(--gray-100);
        }
    }

    .waiting-room-header-content {
        display: flex;
        align-items: center;
        gap: 5px;
        height: 55px;

        &.sm-view {
            display: flex;
            @include media-gt-phone {
                display: none;
            }
        }

        &.lg-view {
            display: none;
            @include media-gt-phone {
                display: flex;
            }
        }

        .back-button {
            .icon {
                font-size: 28px;
                color: var(--gray-700);
            }
        }

        .divider {
            height: 2px;
            width: 185px;
            background-color: var(--gray-700);
        }
    }

    .waiting-room-header-box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 0 20px;
        display: grid;
        grid-template-columns: 645px auto 85px;
        align-items: center;

        .header-box {
            border: 1px solid var(--gray-100);
            padding: 10px 24px 10px 12px;
            border-radius: 6px;
            width: fit-content;
            background-color: white;
            min-width: 500px;
            // display: flex;
            // justify-content: center;
            // flex-direction: column;
            // gap: 5px;
            // height: 57px;

            .title {
                font-size: 16px;
                font-weight: 400;
                line-height: 1;
                display: flex;
                color: var(--gray-900);
                align-items: center;
                margin-bottom: 5px;
                .title-description {
                    font-size: 14px;
                    font-weight: 300;
                    color: var(--gray-900);
                    margin-left: 5px;
                    line-height: 1;
                }
            }
            .subtitle {
                font-size: 14px;
                line-height: 1;
                margin: 0;
                font-weight: 300;

                .name {
                    color: var(--blue-800);
                }
                .arin-text {
                    font-weight: 400;
                    font-size: 13px;
                    color: var(--gray-300);
                    margin: 0 0 1px 2px;
                    line-height: 1;
                }
            }
        }

        .line {
            // .line-inner {
            //     width: 100%;
            //     height: 1px;
            //     background-color: var(--gray-200);
            // }
        }

        .actions-box,
        .leading-box {
            display: flex;
            gap: 5px;
            align-items: center;
            .button {
                border: 1px solid var(--gray-100);
                height: 40px;
                width: 40px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;
            }
        }
    }

    .waiting-room-body-content {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 2rem 0 1rem 0;

        &::-webkit-scrollbar {
            display: none;
        }

        @include media-gt-phone {
            padding: 20px 1rem 40px 0;
        }
    }

    .temporary-record-holder {
        height: calc(100% - 45px);
    }

    // .vimeo {
    //     width: 100%;
    //     height: 500px;
    // }
}
