.brain-activity-arin {
    position: relative;
    min-height: 65px;
    border-radius: 6px;
    border: 1px solid var(--gray-100);
    margin-top: 20px;
    height: calc(100% - 20px);
    &.temporary-records {
       // height: auto;
        max-height: calc(100% - 20px);
    }

    .brain-activity-header {
        position: absolute;
        top: -23px;
        width: 100%;
        padding: 0 16px;

        .leading {
            .title {
                font-weight: 300;
                font-size: 16px;
            }
            svg {
                font-size: 18px;
            }
        }

        .trailing {
            button {
                padding: 5px;
                font-size: 14px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &:not(.chakra-styled) button {
                border: 1px solid var(--gray-300);
            }
        }
    }

    .file-item {
        display: grid;
        grid-template-columns: 30px auto 25px;
        align-items: center;
        border-bottom: 1px solid var(--gray-100);
        // margin-bottom: 10px;
        padding: 10px 0;

        .name {
            line-height: 1;
            margin-bottom: 6px;
            font-weight: 300;
        }
        .date {
            line-height: 1;
            font-weight: 300;
        }

        &:last-child {
            border-bottom: none;
        }

        .icon {
            stroke-width: 1px;
        }
    }

    .file-body {
        overflow-y: auto;
        margin-top: 20px;
        height: calc(100% - 20px);
        padding-top: 20px;
    }

    .with-hidden-link {
        position: relative;

        .hidden-link {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            cursor: pointer;
        }
    }
}
